import { ButtonDanger, ButtonInvisible, Text } from "@primer/components";
import React from "react";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import { useUser } from "../../utils/useUser";
import "./styles.css";

const version = require("../../../package.json").version;

function Footer() {
  const { user } = useUser();

  return (
    <footer>
      {!!user && (
        <div className={"number"}>
          <Text as={"span"}>Signed in as {user.phoneNumber}</Text>

          <ButtonDanger
            className={"logout-btn"}
            variant={"small"}
            mt={3}
            mb={3}
            onClick={() => {
              firebase.auth().signOut();
              window.location.href = "/";
            }}
          >
            Logout
          </ButtonDanger>
        </div>
      )}

      <div className={"container links"}>
        <Link to={"/privacy"}>
          <ButtonInvisible>Privacy Policy</ButtonInvisible>
        </Link>
        <Link to={"/terms"}>
          <ButtonInvisible>Terms and Conditions</ButtonInvisible>
        </Link>
        <Link to={"/contact"}>
          <ButtonInvisible>Contact</ButtonInvisible>
        </Link>
      </div>

      <p
        className="text-center"
        style={{ fontSize: 14, marginTop: 8, color: "gray" }}
      >
        v{version} | © {new Date().getFullYear()} Techulus
      </p>

      <div
        className="text-center"
        style={{ fontSize: 12, marginTop: 8, color: "gray" }}
      >
        Logo made by{" "}
        <a href="https://www.freepik.com" title="Freepik">
          Freepik
        </a>{" "}
        from{" "}
        <a href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>
      </div>
    </footer>
  );
}

export default Footer;
